import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Loader, ScreenLayout, TextInput, Video } from 'components';
import { useFAQ, useFAQVideos } from 'graphql-api';
import { Translation } from 'locales';
import { normalizedString } from 'utils/helpers';
import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';

export const FAQScreen: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const { data, loading } = useFAQ();
  const { data: videoData, loading: videoLoading } = useFAQVideos();

  const lang = i18n.language as keyof Translation;

  const searchedFaq = data?.frequentlyAskedQuestions.filter((item) =>
    normalizedString(item.question[lang]).toLowerCase().includes(normalizedString(searchValue).toLowerCase()),
  );
  const borderColor = accessLevel === AccessLevel.Parent ? 'border-parent-green' : 'border-admin-blue';

  return (
    <ScreenLayout layoutClassName="flex-col px-6 pt-6 md:px-24 md:pt-12">
      <div className="text-teacher-blue text-xl md:text-3xl font-bold mb-4">{t('common.FAQ')}</div>
      <TextInput
        placeholder={t('common.search')}
        type="text"
        name="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        containerClassName="w-full mb-4"
        isSearch
        accessLevel={accessLevel}
      />
      <Loader isLoading={loading || videoLoading}>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          {!!searchedFaq &&
            searchedFaq.map((item, index) => (
              <div className={`flex flex-col rounded-lg border-2 px-4 pt-4 pb-8 ${borderColor}`} key={`faq-card-${item.question[lang]}-${index}`}>
                <div className="text-teacher-blue font-bold">{item.question[lang]}</div>
                <div className="text-teacher-blue">{item.answer[lang]}</div>
              </div>
            ))}
        </div>
        <div className="text-teacher-blue mt-5 text-xl md:text-3xl font-bold mb-4">{t('common.Videos')}</div>
        <div className="grid gap-4 mt-2 grid-cols-1 md:grid-cols-1 xl:grid-cols-2">
          {!!videoData &&
            videoData?.faqVideos.map((item, index) => (
              <div className={`flex flex-col rounded-lg border-2 px-4 pt-4 pb-4 ${borderColor}`} key={`faq-video-card-${item.id}-${index}`}>
                <Video url={item.url} />
              </div>
            ))}
        </div>
      </Loader>
    </ScreenLayout>
  );
};
